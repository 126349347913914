import React, { Component } from 'react'
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

class NewFoodComponent extends Component {
    state = {
        name: '',
        description: '',
        producer: '',
        kcal: '',
        carbs: '',
        protein: '',
        fats: '',
        selectedFile: null,
        is_whole: false,
        is_verified: true,
        bar_code: ''
    };

    componentDidMount() {
        // this.loadFood(this.props.match.params.id);
    }

    // On file select (from the pop up) 
    handleFileChange = event => {
        // Update the state 
        this.setState({ selectedFile: event.target.files[0] });
    };

    // this being an arrow function can make use of 'this'
    // see https://youtu.be/Ke90Tje7VS0?t=4053
    handleFieldChange = (event) => {
        if (event.target.type === 'checkbox') {
            this.setState({ [event.target.id]: event.target.checked });
        } else {
            this.setState({ [event.target.id]: event.target.value });
        }
    }

    handleFormSubmit = (event) => {
        console.log("handleFormSubmit()");

        const formData = new FormData();

        formData.append('img', this.state.selectedFile);

        formData.append('food[name]', this.state.name);
        formData.append('food[description]', this.state.description);
        formData.append('food[kcal]', this.state.kcal);
        formData.append('food[carbs]', this.state.carbs);
        formData.append('food[protein]', this.state.protein);
        formData.append('food[fats]', this.state.fats);
        formData.append('food[is_whole]', this.state.is_whole);
        formData.append('food[is_verified]', this.state.is_verified);
        formData.append('food[bar_code]', this.state.bar_code);

        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': this.props.authToken
            },
            body: formData
        };
        fetch(process.env.REACT_APP_API_URL + '/foods', requestOptions)
            .then(res => res.json())
            .then(data => {
                console.log('->>> first')
                if (data.errors) {
                    Object.keys(data.errors).forEach(function (key) {
                        console.log(data.errors[key]);
                    });
                }

                console.log(data)
            })
            .catch(console.log)

        event.preventDefault();
    }

    fileData = () => {
        if (this.state.selectedFile) {
            return (
                <div>
                    <h2>File Details:</h2>
                    <p>File Name: {this.state.selectedFile.name}</p>
                    <p>File Type: {this.state.selectedFile.type}</p>
                    <p>
                        Last Modified:{" "}
                        {this.state.selectedFile.lastModifiedDate.toDateString()}
                    </p>
                </div>
            );
        }
    };

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">Create New Food</h4>

                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><a href="tbd">Dashboard</a></li>
                                    <li className="breadcrumb-item"><a href="tbd">Foods</a></li>
                                    <li className="breadcrumb-item active">Create New</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-8">
                        <div className="card card-body">
                            <Link to="/foods" className="btn btn-outline-secondary mb-3">Back</Link>

                            <form className="needs-validation" noValidate onSubmit={this.handleFormSubmit}>
                                <div className="row">


                                    <div className="col-md-6">

                                        <div className="form-floating">
                                            <input type="text" className="form-control" id="name" placeholder="Name" value={this.state.name} onChange={this.handleFieldChange} />
                                            <label for="name">Name</label>
                                        </div>

                                        <h4 className='mt-2'>Macronutrient information (per 100g)</h4>

                                        <div className="form-floating mb-3">
                                            <input type="number" className="form-control" id="kcal" placeholder="Enter the kcals" value={this.state.kcal} onChange={this.handleFieldChange} />
                                            <label for="kcal" className="text-muted">KCals</label>
                                        </div>

                                        <div className="form-floating mb-3">
                                            <input type="number" className="form-control" id="fats" placeholder="Enter the fats" value={this.state.fats} onChange={this.handleFieldChange} />
                                            <label for="fats" className="text-warning">Fats</label>
                                        </div>

                                        <div className="form-floating mb-3">
                                            <input type="number" className="form-control" id="carbs" placeholder="Carbs" value={this.state.carbs} onChange={this.handleFieldChange} />
                                            <label for="carbs" className="text-secondary">Carbs</label>
                                        </div>

                                        <div className="form-floating mb-3">
                                            <input type="number" className="form-control" id="protein" placeholder="Proteins" value={this.state.protein} onChange={this.handleFieldChange} />
                                            <label for="protein" className="text-danger">Protein</label>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="is_verified" checked={this.state.is_verified ? 'checked' : ''} onChange={this.handleFieldChange} />
                                            <label className="form-check-label" for="is_verified">
                                                Verified?
                                            </label>
                                        </div>
                                        <div className="form-check mb-3 ">
                                            <input className="form-check-input" type="checkbox" id="is_whole" checked={this.state.is_whole ? 'checked' : ''} onChange={this.handleFieldChange} />
                                            <label className="form-check-label" for="is_whole">
                                                Is whole food?
                                            </label>
                                        </div>

                                        {this.state.is_whole ? '' :
                                            <div className="form-floating mb-3">
                                                <input type="number" className="form-control" id="bar_code" placeholder="Enter the barcode" value={this.state.bar_code} onChange={this.handleFieldChange} />
                                                <label for="bar_code">Bar Code</label>
                                            </div>}

                                        {this.state.is_whole ? '' :
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control" id="producer" placeholder="Producer" value={this.state.producer} onChange={this.handleFieldChange} />
                                                <label for="producer">Producer</label>
                                            </div>}

                                        <div className="form-floating mb-3">
                                            <input type="text" className="form-control" id="description" placeholder="Description" value={this.state.description} onChange={this.handleFieldChange} />
                                            <label for="description">Description</label>
                                        </div>
                                    </div>
                                </div>


                                <div className="row g-3">




                                    <div className="mb-3">
                                        <label htmlFor="formFile" className="form-label">Image</label>
                                        <input className="form-control" type="file" id="formFile" onChange={this.handleFileChange} />
                                    </div>

                                    {this.fileData()}



                                </div>

                                <hr className="my-4" />
                                <div className="row">
                                    <div className="col-6">
                                        <button className="w-100 btn btn-primary btn-lg" type="submit">Create</button>
                                    </div>
                                    <div className="col-6">
                                        <Link to="/foods" className="w-100 btn btn-outline-secondary btn-lg">Cancel</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="avatar-sm mb-3">
                                    <div className="avatar-title bg-soft-success text-success fs-17 rounded">
                                        <i className="ri-smartphone-line"></i>
                                    </div>
                                </div>
                                <h4 className="card-title">{this.state.name === '' ? 'Food Preview' : this.state.name}</h4>

                                <p className="card-text text-muted">{this.state.description}</p>
                            </div>

                            <ul className="list-group list-group-flush">
                                <li className="list-group-item"><strong>Producer</strong> {this.state.producer}</li>
                                <li className="list-group-item">
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <strong className="text-muted">KCals</strong>
                                        </div>
                                        <div className="flex-shrink-0">
                                            {this.state.kcal ? <React.Fragment>{this.state.kcal / 10} <span className='text-muted'> Kcals</span></React.Fragment> : ''}
                                        </div>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <strong className="text-warning">Fats</strong>
                                        </div>
                                        <div className="flex-shrink-0">
                                            {this.state.fats ? <React.Fragment>{this.state.fats / 10} <span className='text-muted'> grams</span></React.Fragment> : ''}
                                        </div>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <strong className="text-secondary">Carbs</strong>
                                        </div>
                                        <div className="flex-shrink-0">
                                            {this.state.carbs ? <React.Fragment>{this.state.carbs / 10} <span className='text-muted'> grams</span></React.Fragment> : ''}
                                        </div>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <strong className="text-danger">Protein</strong>
                                        </div>
                                        <div className="flex-shrink-0">
                                            {this.state.protein ? <React.Fragment>{this.state.protein / 10} <span className='text-muted'> grams</span></React.Fragment> : ''}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>



                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title mb-0">Dropzone</h4>
                            </div>
                            <div className="card-body">
                                <p className="text-muted">DropzoneJS is an open source library that provides drag’n’drop file uploads with image previews.</p>
                                <div className="dropzone">
                                    <div className="fallback">
                                        <input name="file" type="file" multiple="multiple" />
                                    </div>
                                    <div className="dz-message needsclick">
                                        <div className="mb-3">
                                            <i className="display-4 text-muted ri-upload-cloud-2-fill"></i>
                                        </div>
                                        <h4>Drop files here or click to upload.</h4>
                                    </div>
                                </div>
                                <ul className="list-unstyled mb-0" id="dropzone-preview">
                                    <li className="mt-2" id="dropzone-preview-list">
                                        <div className="border rounded">
                                            <div className="d-flex p-2">
                                                <div className="flex-shrink-0 me-3">
                                                    <div className="avatar-sm bg-light rounded">
                                                        <img data-dz-thumbnail className="img-fluid rounded d-block" src="#" alt="Dropzone" />
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <div className="pt-1">
                                                        <h5 className="fs-14 mb-1" data-dz-name>&nbsp;</h5>
                                                        <p className="fs-13 text-muted mb-0" data-dz-size></p>
                                                        <strong className="error text-danger" data-dz-errormessage></strong>
                                                    </div>
                                                </div>
                                                <div className="flex-shrink-0 ms-3">
                                                    <button data-dz-remove className="btn btn-sm btn-danger">Delete</button>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(NewFoodComponent);
