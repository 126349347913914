import React, { Component } from 'react'
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

class MealComponent extends Component {
    state = {
        food: {}
    };

    componentDidMount() {
        this.loadFood(this.props.match.params.id);
    }

    loadFood(id) {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.props.authToken
            }
        };
        fetch(process.env.REACT_APP_API_URL + '/foods/' + id, requestOptions)
            .then(res => res.json())
            .then((data) => {
                this.setState({ food: data })
            })
            .catch(console.log)
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-4">
                        <img src={this.state.food.thumbnail} height="200" alt="thumbnail" />
                        <h1>{this.state.food.name}</h1>
                        {this.state.food.is_verified ? <p><span className="badge bg-success" title="verified nutritional values">verified</span></p> : null}
                        Macronutrient information (per 100g)
                        <ul className="list-group">
                            <li className="list-group-item"><strong>{this.state.food.kcal / 10}</strong> kcals</li>
                            <li className="list-group-item"><strong>{this.state.food.protein / 10}</strong> grams of protein</li>
                            <li className="list-group-item"><strong>{this.state.food.fats / 10}</strong> grams of fat</li>
                            <li className="list-group-item"><strong>{this.state.food.carbs / 10}</strong> grams of carbs</li>
                        </ul>
                    </div>
                    <div className="col-8">
                        <p>
                            <Link to={"/foods/" + this.props.match.params.id + "/edit"} className="btn btn-warning">Edit</Link>
                            <button type="button" className="btn btn-danger">Delete</button>
                        </p>
                        <p>{this.state.food.id}</p>
                        <p>{this.state.food.description}</p>
                        <p>{this.state.food.thumbnail}</p>

                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(MealComponent);