import React, { Component } from 'react'
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

class EditFoodComponent extends Component {
    state = {
        food: {}
    };

    componentDidMount() {
        this.loadFood(this.props.match.params.id);
    }

    loadFood(id) {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.props.authToken
            }
        };
        fetch(process.env.REACT_APP_API_URL + '/foods/' + id, requestOptions)
            .then(res => res.json())
            .then((data) => {
                this.setState({ food: data })
                // console.log(data.foods)
            })
            .catch(console.log)
    }

    // this being an arrow function can make use of 'this'
    // see https://youtu.be/Ke90Tje7VS0?t=4053
    handleNameChange = (event) => {
        const food = this.state.food;
        food.name = event.target.value
        this.setState({ food });
    }

    // this being an arrow function can make use of 'this'
    // see https://youtu.be/Ke90Tje7VS0?t=4053
    handleDescriptionChange = (event) => {
        const food = this.state.food;
        food.description = event.target.value
        this.setState({ food });
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-4">
                        <img src={this.state.food.thumbnail} height="200" alt="thumbnail" />
                        <h1>{this.state.food.name}</h1>
                    </div>
                    <div className="col-8">
                        <Link to={"/foods/" + this.props.match.params.id} className="btn btn-outline-secondary mb-3">Back</Link>
                        <form className="needs-validation" novalidate>
                            <div className="row g-3">
                                <div className="col-12">
                                    <label for="nameField" className="form-label">Name</label>
                                    <input type="text" className="form-control" id="nameField" value={this.state.food.name} onChange={this.handleNameChange} required />
                                    <div className="invalid-feedback">
                                        Valid first name is required.
                                    </div>
                                </div>

                                <div className="col-12">
                                    <label for="nameField" className="form-label">Description</label>
                                    <input type="text" className="form-control" id="nameField" value={this.state.food.description} onChange={this.handleDescriptionChange} required />
                                    <div className="invalid-feedback">
                                        Valid first name is required.
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <label for="formFile" className="form-label">Image</label>
                                    <input className="form-control" type="file" id="formFile" />
                                </div>

                                <p>Macronutrient information (per 100g):</p>

                                <div className="col-3">
                                    <label for="kcalField" className="form-label">KCals</label>
                                    <input type="number" className="form-control" id="kcalField" value={this.state.food.kcal / 10} required />
                                    <div className="invalid-feedback">
                                        Valid first name is required.
                                    </div>
                                </div>

                                <div className="col-3">
                                    <label for="carbField" className="form-label">Carbohidrates</label>
                                    <input type="number" className="form-control" id="carbField" value={this.state.food.carbs / 10} />
                                    <div className="invalid-feedback">
                                        Valid first name is required.
                                    </div>
                                </div>

                                <div className="col-3">
                                    <label for="proteinField" className="form-label">Protein</label>
                                    <input type="number" className="form-control" id="proteinField" value={this.state.food.protein / 10} required />
                                    <div className="invalid-feedback">
                                        Valid first name is required.
                                    </div>
                                </div>

                                <div className="col-3">
                                    <label for="fatsField" className="form-label">Fats</label>
                                    <input type="number" className="form-control" id="fatsField" value={this.state.food.fats / 10} required />
                                    <div className="invalid-feedback">
                                        Valid first name is required.
                                    </div>
                                </div>


                            </div>

                            <hr className="my-4" />
                            <div className="row">
                                <div className="col-6">
                                    <button className="w-100 btn btn-primary btn-lg" type="submit">Save</button>
                                </div>
                                <div className="col-6">
                                    <Link to={"/foods/" + this.props.match.params.id} className="w-100 btn btn-outline-secondary btn-lg">Cancel</Link>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(EditFoodComponent);