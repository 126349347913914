import React, { Component } from 'react'
import ConsumptionLogsPerDayComponent from './consumptionLogsPerDayComponent';
import TodayMacrosComponent from './todayMacrosComponent';

class ConsumptionLogListComponent extends Component {
    state = {
        days_to_load: 30,
        basalMetabolicRate: localStorage.getItem('target_bmr'),
        target_fats: localStorage.getItem('target_fats'),
        target_carbs: localStorage.getItem('target_carbs'),
        target_protein: localStorage.getItem('target_protein')
    };

    componentDidMount() {
        // this.loadConsumptionLogs(0);
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">Consumption Logs</h4>

                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><a href="/">Dashboard</a></li>
                                    <li className="breadcrumb-item active">Foods</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">

                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-8">
                        <ConsumptionLogsPerDayComponent />
                    </div>
                    <div className="col-xl-4">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title mb-0">Logs for day</h4>
                            </div>
                            <div className="card-body">
                                <form action="#">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="mt-0">
                                                <label className="form-label mb-0">MinDate and MaxDate</label>
                                                <p className="text-muted">Set
                                                    <code>data-provider="flatpickr" data-date-format="d M, Y" data-minDate="Your Min. Date" data-maxDate="Your Max. date"</code>
                                                    attribute.
                                                </p>
                                                <input type="text" className="form-control" data-provider="flatpickr"
                                                    data-date-format="d M, Y"
                                                    data-mindate="25 12, 2021"
                                                    data-maxdate="29 12,2021" />
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                        <TodayMacrosComponent targetFats={this.state.target_fats} targetCarbs={this.state.target_carbs} targetProtein={this.state.target_protein} basalMetabolicRate={this.state.basalMetabolicRate} />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ConsumptionLogListComponent;
