import React, { Component } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import FoodListComponent from './pages/foods/foodListComponent';
import HeaderComponent from './layout/headerComponent';
import FoodComponent from './pages/foods/foodComponent';
import EditFoodComponent from './pages/foods/editFoodComponent';
import DashboardComponent from './pages/dashboard/dashboardComponent';
import NewFoodComponent from './pages/foods/newFoodComponent';
import ConsumptionLogListComponent from './pages/consumption_logs/consumptionLogListComponent';
import FooterComponent from './layout/footerComponent';
import MealListComponent from './pages/meals/mealListComponent';
import MealComponent from './pages/meals/mealComponent';
import ProfileComponent from './pages/profile/profileComponent';

class MainRouterComponent extends Component {
    render() {
        return (
            <React.Fragment>
                <BrowserRouter>
                    <HeaderComponent authEmail={this.props.authEmail}></HeaderComponent>

                    <div className="main-content">
                        <div className="page-content">
                            <div className="container-fluid">
                                <Switch>
                                    <Route path="/" exact>
                                        <DashboardComponent authToken={this.props.authToken}></DashboardComponent>
                                    </Route>

                                    <Route path="/foods" exact component={() => <FoodListComponent authToken={this.props.authToken}></FoodListComponent>} />
                                    <Route path={"/foods/new"} exact component={() => <NewFoodComponent authToken={this.props.authToken}></NewFoodComponent>} />
                                    <Route path={"/foods/:id"} exact component={() => <FoodComponent authToken={this.props.authToken}></FoodComponent>} />
                                    <Route path={"/foods/:id/edit"} component={() => <EditFoodComponent authToken={this.props.authToken}></EditFoodComponent>} />

                                    <Route path="/meals" exact component={() => <MealListComponent authToken={this.props.authToken}></MealListComponent>} />
                                    <Route path={"/meals/:id"} exact component={() => <MealComponent authToken={this.props.authToken}></MealComponent>} />

                                    <Route path="/consumption_logs" exact component={() => <ConsumptionLogListComponent authToken={this.props.authToken}></ConsumptionLogListComponent>} />

                                    <Route path="/profile" exact component={() => <ProfileComponent authToken={this.props.authToken}></ProfileComponent>} />
                                </Switch>
                            </div>
                        </div>

                        <FooterComponent></FooterComponent>
                    </div>
                </BrowserRouter>
            </React.Fragment>
        );
    }
}

export default MainRouterComponent;