import React, { useState, useEffect } from 'react'
import { useQuery, gql } from "@apollo/client";
import { Chart, registerables } from 'chart.js';
import { getDate } from '../../utils/getDate';

const MacrosChartComponent = (props) => {
    const DAY_ENTRY = gql`
        query GetDayEntry($date: String!) {
            dayEntry(date: $date) {
                date
                consumptionTotals {
                    carbs
                    fats
                    protein
                }
            }
        }
    `;

    const [initialLoadCompleted, setInitialLoadCompleted] = useState(false);
    const [remainingDaysToLoad, setRemainingDaysToLoad] = useState(props.daysToLoad - 1);
    const [dayEntries, setDayEntries] = useState([]);
    const [macronutrientChart, setMacronutrientChart] = useState(null);

    useEffect(() => {
        if (dayEntries.length > 0) {
            refreshChart(remainingDaysToLoad + 1 - props.daysToLoad);
        }
    }, [dayEntries]);

    const { loading, error, data } = useQuery(DAY_ENTRY, {
        variables: {
            date: getDate(remainingDaysToLoad - props.daysToLoad + 1)
        },
        onCompleted: (data) => {
            setDayEntries([data.dayEntry, ...dayEntries])

            if (remainingDaysToLoad > 0) {
                setRemainingDaysToLoad(remainingDaysToLoad - 1);
            }
        }
    });

    const initializeMacronutrientChart = () => {
        // make sure this is only initialized once
        if (macronutrientChart == null) {
            const ctx = document.getElementById('macronutrientChart');
            Chart.register(...registerables);
            setMacronutrientChart(new Chart(ctx, {
                type: 'line',
                data: {
                    labels: [],
                    datasets: [
                        {
                            yAxisID: 'yLeft',
                            label: 'Fats',
                            data: [],
                            borderColor: '#d4af37',
                            backgroundColor: '#d4af37',
                            tension: 0.1,
                            pointStyle: 'circle',
                            pointRadius: 0,
                            pointHoverRadius: 5,
                            barPercentage: 1.0
                        },
                        {
                            yAxisID: 'yLeft',
                            label: 'Carbs',
                            data: [],
                            borderColor: '#0000FF',
                            backgroundColor: '#0000FF',
                            tension: 0.1,
                            pointStyle: 'circle',
                            pointRadius: 0,
                            pointHoverRadius: 5,
                            barPercentage: 1.0
                        },
                        {
                            yAxisID: 'yLeft',
                            label: 'Protein',
                            data: [],
                            borderColor: '#FF0000',
                            backgroundColor: '#FF0000',
                            tension: 0.1,
                            pointStyle: 'circle',
                            pointRadius: 0,
                            pointHoverRadius: 5,
                            barPercentage: 1.0
                        }
                    ]
                },
                options: {
                    responsive: true,
                    interaction: {
                        mode: 'index',
                        intersect: false,
                    },
                    scales: {
                        yLeft: {
                            beginAtZero: true,
                            position: 'left',
                            title: 'Grams',
                            grid: {
                                drawOnChartArea: true,
                                color: function (context) {
                                    if (context.tick.value == 0) {
                                        return '#CCCCCC';
                                    }
                                },
                            },
                            stacked: false
                        },
                        x: {
                            drawBorder: false,
                            grid: {
                                drawOnChartArea: false
                            },
                            stacked: true
                        }
                    }
                }
            }));
        }
    }

    const refreshChart = (offset) => {
        if (offset === 0) {
            macronutrientChart.data.labels.unshift('Today');
        } else {
            macronutrientChart.data.labels.unshift(getDate(offset));
        }

        macronutrientChart.data.datasets.forEach((dataset) => {
            if (dataset.label === 'Fats') {
                dataset.data.unshift(dayEntries[0].consumptionTotals.fats / 10);
            }
            if (dataset.label === 'Carbs') {
                dataset.data.unshift(dayEntries[0].consumptionTotals.carbs / 10);
            }

            if (dataset.label === 'Protein') {
                dataset.data.unshift(dayEntries[0].consumptionTotals.protein / 10);
            }

        });
        macronutrientChart.update();
    }

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        // make sure that the HTML is rendered before initializing the chart
        if (document.getElementById('macronutrientChart') && macronutrientChart == null) {
            initializeMacronutrientChart();
        }

        // Specify how to clean up after this effect:
        // TODO: work in progress -  commenting out on april 7th
        // return function cleanup() {
        //     setMacronutrientChart(null);
        // };
    });

    // rendering below

    if (loading && !initialLoadCompleted) {
        setInitialLoadCompleted(true);
        return <p>Loading Macronutrient Chart...</p>;
    }

    if (error) {
        return <p>Error Loading Macronutrient Chart :(</p>;
    }

    return <React.Fragment>
        <div className="row">
            <div className="col-xl-12">
                <div className="card">
                    <div className="card-header border-0 align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">Eaten Macronutrient Distribution</h4>
                        <div>
                            <button type="button" className="btn btn-soft-secondary btn-sm">
                                ALL
                            </button>
                            <button type="button" className="btn btn-soft-secondary btn-sm">
                                1M
                            </button>
                            <button type="button" className="btn btn-soft-secondary btn-sm">
                                6M
                            </button>
                            <button type="button" className="btn btn-soft-primary btn-sm">
                                1Y
                            </button>
                        </div>
                    </div>

                    <div className="card-body p-2 pb-2">
                        <canvas id="macronutrientChart" width="400" height="50"></canvas>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
}

export default MacrosChartComponent;
